<template>
  <div>
    <HeaderNavHomepage />

    <main class="main-wrapper">
      <div class="main-card">
        <section class="hero">
          <h1>
            <span class="nowrap">D’Festa Philippines:</span> The Ultimate
            Celebration of <span class="nowrap">K-Pop</span>,
            <span class="nowrap">Powered by</span> HelixPay
          </h1>
          <div class="post-date">January 10, 2025</div>
        </section>

        <div class="main">
          <div class="image-container">
            <vLazyImage src="/img/articles/dfesta.png" />
          </div>

          <p>
            D’Festa Philippines, dubbed a global K-pop festival, opened its
            doors last December 20, 2024, and runs until March 19, 2025. This
            immersive event commemorates the 10th anniversary of Korean news
            outlet Dispatch, offering fans an unparalleled opportunity to
            celebrate K-pop’s biggest stars through interactive exhibits,
            activities, and exclusive content.
          </p>

          <p>
            The festival spotlights eight of K-pop’s most iconic groups: BTS,
            Twice, Enhypen, Tomorrow x Together, Stray Kids, NCT Dream, NCT 127,
            and Seventeen. Visitors can immerse themselves in dynamic photo
            zones, behind-the-scenes footage, and customized merchandise that
            resonates with their favorite artists, making it a must-visit
            destination for enthusiasts of the genre.
          </p>

          <h2>Revolutionizing the Ticketing Experience</h2>
          <p>
            As the official ticketing partner for D’Festa Philippines, HelixPay
            is at the forefront of ensuring fans can easily secure their entry
            to this highly anticipated event. Over the festival’s three-month
            run, thousands of attendees are expected, and HelixPay’s robust
            platform is designed to deliver a seamless and stress-free
            experience.
          </p>

          <p>Through HelixPay’s user-friendly system, fans can:</p>

          <ul>
            <li>
              <div>Purchase Tickets Online</div>
              <div>
                Access tickets from the comfort of their homes with real-time
                availability updates, ensuring a transparent buying process.
              </div>
            </li>
            <li>
              <div>Enjoy Fast and Efficient Entry</div>
              <div>
                Attendees skip long queues by presenting their e-tickets for
                quick venue access.
              </div>
            </li>
            <li>
              <div>Prevent Scalping Issues</div>
              <div>
                With anti-scalping safeguards, tickets remain accessible to
                genuine fans.
              </div>
            </li>
            <li>
              <div>Manage Flexible Payment Options</div>
              <div>
                Choose from a variety of secure payment methods, catering to a
                diverse audience.
              </div>
            </li>
            <li>
              <div>Receive Personalized Support</div>
              <div>
                HelixPay’s customer service ensures queries and issues are
                resolved promptly.
              </div>
            </li>
          </ul>

          <h2>Enhancing the Merchandising Experience</h2>

          <p>
            HelixPay goes beyond ticketing by transforming the merchandise
            experience at D’Festa Philippines. With thousands of fans expected
            over the festival’s three-month run, HelixPay’s advanced merch
            system ensures efficiency and convenience for both organizers and
            attendees.
          </p>

          <ul>
            <li>
              <div>Diverse Merchandise Offerings</div>
              <div>
                Fans can purchase exclusive items such as postcards, stickers,
                pins, shirts, and more to commemorate the event.
              </div>
            </li>
            <li>
              <div>Real-Time Inventory Insights</div>
              <div>
                Organizers receive up-to-the-minute data on sales and inventory,
                enabling better decision-making and restocking efficiency.
              </div>
            </li>
            <li>
              <div>Streamlined Menu Checkout Process</div>
              <div>
                Fans can browse the merchandise at their own pace, select their
                desired items from a menu checklist, and proceed to the cashier
                for payment before collecting their items. This process
                simplifies transactions and reduces waiting time.
              </div>
            </li>
            <li>
              <div>Secure and Anti-Theft System</div>
              <div>
                Built-in security features ensure transactions and inventory
                remain protected, providing peace of mind for both organizers
                and buyers.
              </div>
            </li>
          </ul>

          <p>
            HelixPay’s involvement underscores its dedication to enhancing the
            event experience for fans and organizers alike. By leveraging
            cutting-edge ticketing technology, the company ensures attendees
            spend less time navigating logistics and more time immersed in the
            magic of K-pop.
          </p>

          <p>
            Secure your D’Festa tickets today at
            <a href="https://tickets.dfestaph.com"
              >https://tickets.dfestaph.com</a
            >. For inquiries about how HelixPay can enhance your events, contact
            us at <a href="mailto:hello@helixpay.ph">hello@helixpay.ph</a>.
          </p>
        </div>
      </div>
    </main>

    <MainFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import vLazyImage from 'v-lazy-image';

const metaImagePath = 'img/articles/dfesta.png';
const articlePath = '/articles/dfesta';

useHead(
  buildPageMeta({
    title: `D’Festa Philippines: The Ultimate Celebration of K-Pop, Powered by HelixPay`,
    description: ` D’Festa Philippines, dubbed a global K-pop festival, opened its doors last December 20, 2024, and runs until March 19, 2025.`,
    url: `https://www.helixpay.ph/${articlePath}`,
    image: `https://www.helixpay.ph/${metaImagePath}`,
  })
);
</script>

<style scoped>
.main-wrapper {
  background-image: url('/img/articles/dfesta-bg.png');
  padding: 2rem 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #474848;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.main p:first-child {
  margin-top: 0 !important;
}

.main-card {
  background: white;
  margin: 0 auto;
  max-width: 880px;
  border-radius: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);
}

.hero {
  padding: 4rem 1.5rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0 auto;
  font-size: 1.15rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  font-weight: 700;
  max-width: 1080px;
}

.image-container {
  padding-bottom: 100%;
  background: #b5c9c4;
  position: relative;
}

.image-container img {
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.main strong {
  font-weight: 600;
}

.main h2 {
  font-size: 1.3rem;
}

.main p,
.main h2,
.main ul {
  margin: 2rem 1.5rem;
}

.main p {
  font-size: 0.9rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .main-wrapper {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero {
    padding: 6rem 3rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .main p,
  .main h2,
  .main ul {
    margin: 3rem auto;
    max-width: 512px;
  }

  .main p {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .hero h1 {
    font-size: 2rem;
  }

  .main p,
  .main h2,
  .main ul {
    max-width: 610px;
  }

  .main p {
    font-size: 1.15rem;
  }
}

.main ul li + li {
  margin-top: 18px;
}

.main ul li div:first-child {
  font-weight: 500;
  margin-bottom: 6px;
}

.main h2 {
  margin-bottom: 0;
}

.main h2 + p {
  margin-top: 1rem;
}

.hero h1 {
  font-size: 1.75rem;
  font-weight: bold;
}

.photo-credits {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  opacity: 0.7;
}

.post-date {
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.7;
}

strong {
  font-weight: 600;
}

a {
  color: #25a4e1;
}

.main ul {
  padding-left: 0;
  list-style: none;
}

.main ul li {
  position: relative;
  padding-left: 1.5em;
}

.main ul li:before {
  content: '✔';
  position: absolute;
  left: 0;
  width: 1em;
  height: 1em;
}
</style>
